<template>
  <transition name="fade">
    <div v-if="show" class="modal-container">
      <div class="w-full max-w-2xl">
        <ui-card :heading="$t('fields.edit_fields')">
          <p>{{ $t("fields.following_fields_are_always_required") }}</p>

          <ul class="ml-5 list-disc">
            <li>{{ $t("fields.first_name") }}</li>
            <li>{{ $t("fields.last_name") }}</li>
            <li>{{ $t("fields.email") }}</li>
          </ul>

          <p class="mt-4 mb-5">
            {{ $t("fields.you_can_add_two_required_fields") }}
          </p>

          <!-- Field 1 -->
          <ui-form-field :label="''">
            <el-input
              type="text"
              :placeholder="$t('fields.new_field_name')"
              v-model="field1Internal"
            ></el-input>
          </ui-form-field>

          <!-- Field 2 -->
          <ui-form-field :label="''">
            <el-input
              type="text"
              :placeholder="$t('fields.new_field_name')"
              v-model="field2Internal"
            ></el-input>
          </ui-form-field>

          <template v-slot:footer>
            <div class="flex">
              <ui-link type="primary" @click="handleConfirm">{{
                $t("actions.save")
              }}</ui-link>
              <ui-link type="text" class="ml-4" @click="handleCancel">{{
                $t("actions.cancel")
              }}</ui-link>
            </div>
          </template>
        </ui-card>
      </div>
    </div>
  </transition>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiFormField from "@/components/ui/UiFormField";
import UiLink from "@/components/ui/UiLink";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    field1: {
      type: String,
      required: false,
      default: null,
    },
    field2: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    UiCard,
    UiLink,
    UiFormField,
  },

  data() {
    return {
      field1Internal: null,
      field2Internal: null,
    };
  },

  mounted() {
    this.field1Internal = this.field1;
    this.field2Internal = this.field2;
  },
  methods: {
    handleConfirm() {
      this.$emit("confirm", this.field1Internal, this.field2Internal);
    },

    handleCancel() {
      this.$emit("cancel");
    },
    emitField1(newValue, _) {
      if (newValue === "") newValue = null;
      this.$emit("field1Changed", newValue);
    },
    emitField2(newValue, _) {
      if (newValue === "") newValue = null;
      this.$emit("field2Changed", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
