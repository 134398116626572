<template>
  <responsive-ui-list
    :disableXScroll="false"
    :heading="heading"
    :headings="[
      $t('fields.date'),
      $tc('models.group', 1),
      $t('fields.location'),
      $t('fields.participants'), // e.g. 18/20
      $t('fields.actions'), // slett, edit
    ]"
    :sort="[
      { prop: 'startDateTime', type: false },
      undefined,
      { prop: 'location', type: false },
      { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
    ]"
    :items="sessions"
  >
    <template v-slot:default="props">
      <template>
        <ui-list-data
          class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') === $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
            `"
        ></ui-list-data>
        <ui-list-data
          class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') != $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')} 
                - 
                ${$d(new Date(props.item.endDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
            `"
        ></ui-list-data>
      </template>
      <ui-list-data
        class="list_data"
        :text="`
            ${$d(new Date(props.item.startDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}
            -
            ${$d(new Date(props.item.endDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}`"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        type="tag"
        :text="props.item.location"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        :text="`${props.item.participants == null ? 0 :props.item.participants.length} / ${props.item.maxParticipants}`"
      ></ui-list-data>

      <ui-list-data small class="list_data">
        <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin']">
          <ui-link @click="$emit('editSession', props.item)">
            {{ $tc("actions.edit") }}
          </ui-link>
          <ui-button
            @click="$emit('deleteSession', props.item)"
            type="red"
            size="small"
            class="ml-5"
            uppercase
          >
            {{ $t("actions.delete") }}
          </ui-button>
        </auth-checker>
      </ui-list-data>
    </template>
  </responsive-ui-list>
</template>

<script>
import ResponsiveUiList from '../components/ResponsiveUiList'
import UiListData from "@/components/ui/UiListData";
import AuthChecker from "../../auth/components/AuthChecker";
import UiButton from "@/components/ui/UiButton";
import UiLink from "@/components/ui/UiLink";
import {capitalized} from "@/extras";

export default {
  props: {
    heading: {
      type: String,
    },
    sessions: {
      type: Array,
      required: true,
    },
  },

  watch: {
    sessions(_, __){
      // Create uppercase locations to ensure correct sorting
      this.sessions.forEach((session) => {
        session.location = capitalized(session.location);
      });
    }
  },

  components: {
    UiButton,
    ResponsiveUiList,
    UiListData,
    UiLink,
    AuthChecker,
  },

  methods: {
    getPercentage(item) {
      if (item.progress && item.progress.progressPercentage) {
        return item.progress.progressPercentage;
      } else {
        if (item.assignedCount && item.completedCount) {
          return Math.floor((item.completedCount / item.assignedCount) * 100);
        } else {
          return 0;
        }
      }
    },
  },
};
</script>

<style scoped>
.list_data {
  padding: 10px 24px;
}
</style>
