<template>
  <transition name="fade">
    <div
      v-if="show"
      class="modal-container"
    >
      <div class="w-full max-w-xl">
        <div class="bg-white rounded overflow-hidden">
          <!-- Heading -->
          <div class="p-4 md:p-6 font-bold uppercase">{{ this.$t("actions.add_new_group") }}</div>

          <!-- Main Content -->
          <div class="px-4 pb-4 md:px-6">
            <div class="mt-4">
              <ui-form
                @submit="handleConfirm()"
                @cancel="handleCancel()"
              >
                <div class="flex flex-row justify-between">
                  <ui-form-field
                    :label="$t('fields.date')"
                    rules="required"
                    :minimize="true"
                  >
                    <el-date-picker
                      v-model="dateInput"
                      rules="required"
                      type="date"
                      class="w-48"
                      format="dd/MM/yyyy"
                    />
                  </ui-form-field>

                  <ui-form-field
                    :label="$t('fields.instructor')"
                    rules="required"
                    :minimize="true"
                  >
                    <el-select
                      v-model="instructorModel"
                      value-key="id"
                      style="width: 220px"
                      :no-data-text="$t('errors.no_data')"
                      :loading-text="$t('actions.fetching_data')"
                      :placeholder="$t('actions.select')"
                    >
                      <el-option
                        v-for="item in instructors"
                        :key="item.id"
                        :label="item.firstName + ' ' + item.lastName"
                        :value="item"
                      />
                    </el-select>
                  </ui-form-field>
                </div>
                <div class="flex flex-row justify-between">
                  <ui-form-field
                    :label="$t('fields.start')"
                    rules="required"
                    :minimize="true"
                  >
                    <el-time-picker
                        v-model="startTimeInput"
                        datatype=""
                        :placeholder="$t('fields.start')"
                        end-placeholder="End time"
                        format="HH:mm"
                        @change="$refs.endTimeRef.focus()">
                    </el-time-picker>
                  </ui-form-field>
                  <ui-form-field
                      :label="$t('fields.end')"
                      rules="required"
                      :minimize="true"
                  >
                    <el-time-picker
                        ref="endTimeRef"
                        v-model="endTimeInput"
                        :placeholder="$t('fields.end')"
                        format="HH:mm">
                    </el-time-picker>
                  </ui-form-field>
                </div>

                <p v-if="shouldShowTimeWarningText" class="mb-6 text-red-500 italic">
                  {{
                    $t("warnings.end_date_will_be_next_day",
                        { date: endDateTime.toLocaleString([],
                              { year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              }) }) }}
                </p>
                <ui-form-field
                  :label="$t('fields.location')"
                  rules="required"
                  :minimize="true"
                >
                  <el-input v-model="location" />
                </ui-form-field>
                <ui-form-field
                  rules="required"
                  :label="$t('fields.participants')"
                  :minimize="true"
                >
                  <el-input-number
                    v-model="maxParticipants"
                    type="number"
                    :min="1"
                    rules="required"
                  />
                </ui-form-field>
                <ui-form-field
                  class="mb-5"
                  :label="$t('language.language')"
                  rules="required"
                  :minimize="true"
                >
                  <el-select
                    v-model="language"
                    placeholder="Select"
                    style="width: 220px"
                  >
                    <el-option
                      v-for="item in supportedLanguages()"
                      :key="item.value"
                      :label="$t(`language.${item.label}`)"
                      :value="item.value"
                    />
                  </el-select>
                </ui-form-field>
              </ui-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UiForm from "@/components/ui/UiForm";
import UiFormField from "@/components/ui/UiFormField.vue";
import { SupportedLanguages } from "@/i18n/lang";

export default {
  name: "EditSessionModal",

  components: {
    UiFormField,
    UiForm,
  },

  props: {
    instructors: {
      type: Array,
      required: true,
    },
    session: {
      type: Object,
      required: true,
    },
    defaultInstructor: {
      type: Object,
      required: false,
      default: null
    },
    defaultLocation: {
      type: String,
      required: false,
      default: null
    },
    defaultStartDate: {
      type: Date,
      required: false,
      default: null
    },
    show: {
      type: Boolean,
      required: true,
    },
    confirmText: {
      type: String,
      default: function () {
        return this.$t("actions.confirm");
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t("actions.cancel");
      },
    },
  },

  watch: {
    startTimeInput(newVal,_) {
      const setEndDateSuggestion = this.startTimeInput.getHours() <= 22;
      if (setEndDateSuggestion) {
        this.endTimeInput = this.createDateWithHourDelta(newVal, 1);
      }
    },
  },

  computed: {
    /** @returns {Date} */
    startDateTime: function () {
      return this.createDateWithTime(this.dateInput, this.startTimeInput);
    },

    /** @returns {Date} */
    endDateTime: function () {
      // Can't define when end time is before start time (need to check which day end-time is)
      if (!this.startTimeInput || !this.endTimeInput) return null;

      if (this.isHoursAndMinutesBefore(this.endTimeInput, this.startTimeInput)) {
        // End date time is next day
        const endDt = this.createDateWithTime(this.dateInput, this.endTimeInput);
        return this.createDateWithDayDelta(endDt, 1);
      }

      // Within same day as start time
      return this.createDateWithTime(this.dateInput, this.endTimeInput);
    },

    /** @returns {boolean} */
    shouldShowTimeWarningText() {
      if (!this.startDateTime || !this.endDateTime) return false;
      return this.isHoursAndMinutesBefore(this.endDateTime, this.startDateTime);
    },
    returnModel: function () {
      const sessionModel = {
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
        language: this.language,
        location: this.location,
        instructorModel: this.instructorModel,
        maxParticipants: this.maxParticipants,
        progress: {
          assignedCount: this.maxParticipants,
          completedCount: this.session.progress == null ? 0 : this.session.progress.completedCount,
        },
      };
      return {
        ...this.session,
        ...sessionModel,
      };
    },
  },

  data() {
    let initStartDate = this.session.startDateTime == null ? null : new Date(this.session.startDateTime);
    if (initStartDate == null) {
      initStartDate = this.createDefaultStartDate();

      // Set default start date if not null and it's not too late
      const defaultStartDateSet = this.defaultStartDate != null;
      const suggestionHourLimit = 22;
      if (defaultStartDateSet && this.defaultStartDate.getHours() < suggestionHourLimit) {
        initStartDate = this.defaultStartDate;
      }
    }

    // Set end date to start date + 1 hour
    const initEndDate = this.session.endDateTime == null ?
        this.createDateWithHourDelta(initStartDate, 1) : new Date(this.session.endDateTime);

    let initInstructor = this.session.instructor;
    if (initInstructor == null) {
      // No previous set instructor, set default value
      initInstructor = this.defaultInstructor;
    }

    let initLocation = this.session.location;
    if (initLocation == null) {
      // No previous set location, set default value
      initLocation = this.defaultLocation;
    }

    return {
      language: this.session.language,

      /**
       * Binding to date field
       * @type {Date}
       */
      dateInput: initStartDate,

      /**
       * Binding to start time field
       * @type {Date}
       */
      startTimeInput: initStartDate,

      /**
       * Binding to end time field
       * @type {Date}
       */
      endTimeInput: initEndDate,

      location: initLocation,
      instructorModel: initInstructor,
      maxParticipants: this.session.maxParticipants,
      timeWarningText: null,
    };
  },

  methods: {
    /**
     * Get time component from a date object
     * @param date The date object to extract time component from
     * @returns {string}
     */
    getTimeFromDateTime(date) {
      return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    },
    /**
     * Creates a date with a new time component.
     * @param date The date to change time on
     * @param {Date} dateTime The new time of the date
     * @returns {Date}
     */
    createDateWithTime(date, dateTime) {
      const newDate = structuredClone(date);

      // Splitting hours and minutes, and setting them separately
      const newHours = dateTime.getHours();
      const newMinutes = dateTime.getMinutes();
      newDate.setHours(newHours);
      newDate.setMinutes(newMinutes);

      return newDate;
    },

    /**
     * Creates a default date, which is at 07:00 in one day from this day.
     * @returns {Date}
     */
    createDefaultStartDate() {
      const today = new Date();
      today.setHours(7);
      today.setMinutes(0);

      return this.createDateWithDayDelta(today, 1);
    },

    /**
     * Creates a date with the time set to a specified amount of hour(s) later.
     * @param date The date to base the time on, new date is one hour after this
     * @param hoursAfter How many hour(s) after original date to set
     * @returns {Date} A new date with a time set to X hour(s) after given date
     */
    createDateWithHourDelta(date, hoursAfter) {
      const newDate = structuredClone(date);
      newDate.setTime(newDate.getTime() + (hoursAfter*60*60*1000));

      return newDate;
    },

    /**
     * Creates a date with the time set to a specified amount of day(s) later.
     * @param date The date to base the time on, new date is one hour after this
     * @param daysLater How many day(s) after original date to set
     * @returns {Date} A new date with a time set to X day(s) after given date
     */
    createDateWithDayDelta(date, daysLater) {
      const hoursPerDay = 24;
      return this.createDateWithHourDelta(date, daysLater*hoursPerDay);
    },

    /**
     * Decides if a date is before another based on time components (hour & minute)
     * @param date {Date} The date to check if time is before other datetime
     * @param otherDate {Date} The date to compare to
     * @returns {boolean} true if time of date is before otherTime, false otherwise
     */
    isHoursAndMinutesBefore(date, otherDate) {
      return date.getHours() < otherDate.getHours() ||
        (date.getHours() === otherDate.getHours() && date.getMinutes() < otherDate.getMinutes());
    },

    supportedLanguages() {
      return SupportedLanguages;
    },

    handleConfirm() {
      this.$emit("confirm", this.returnModel);
    },

    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
